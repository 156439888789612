import React, { useState } from 'react';
import { CalendarIcon, TimeIcon } from '@chakra-ui/icons';
import {
  Box,
  HStack,
  Icon,
  Spacer,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { FaEdit, FaRegWindowClose, FaTicketAlt } from 'react-icons/fa';
import { FiUser, FiBox, FiPlay } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { BookingResult } from 'src/api/interfaces/booking';
import dayjs from 'dayjs';
import { ErrorModal } from 'src/components/Modal/ModalVariants/ErrorModal';
import { cancelBooking } from 'src/api/services/booking';
import usePHToast from 'src/hooks/useToast';
import { BsAlarm } from 'react-icons/bs';
import { PophausModal } from 'src/components/Modal';

export const useAdminTicketsColumns = (realoadGet?: any) => {
  const columnHelper = createColumnHelper<BookingResult>();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  function truncateName(name: string, maxLength: number) {
    if (name.length <= maxLength) {
      return name;
    }

    const truncatedName = name.substring(0, maxLength - 3) + '...';
    return truncatedName;
  }

  const typePayment = (id: number) => {
    if (id === 1) {
      return 'Crédito';
    } else if (id === 2) {
      return 'Débito';
    } else if (id === 3) {
      return 'Pix';
    } else if (id === 4) {
      return 'Pagamento Local Crédito';
    } else if (id === 5) {
      return 'Pagamento Local Débito';
    } else if (id == 6) {
      return 'Pagamento Local Pix';
    } else if (id == 7) {
      return 'Pagamento Local Cortesia';
    } else if (id == 8) {
      return 'Pagamento Local Atendimento';
    } else if (id == 9) {
      return 'Pagamento Online Cortesia';
    } else {
      return 'Aguardando Pagamento';
    }
  };

  const columns: any = [
    columnHelper.accessor('startDate', {
      cell: (info) => (
        <Stack direction="row" alignItems="center">
          <CalendarIcon />

          <Text variant="text">
            {dayjs(info.getValue()).format('DD/MM/YYYY')}
          </Text>
        </Stack>
      ),
      header: () => <span>Data de uso</span>,
    }),

    columnHelper.accessor((r) => r.user?.name, {
      id: 'user.name',
      cell: (info) => (
        <Stack direction="row" alignItems="center">
          <FiUser />

          <Text variant="text">{truncateName(`${info.getValue()}`, 15)}</Text>
        </Stack>
      ),
      header: () => <span>Username</span>,
    }),

    columnHelper.accessor('bookingRooms', {
      cell: (info) => {
        const rooms = info.getValue();
        const room = rooms?.[0];
        return (
          <Stack direction="row" alignItems="center">
            <TimeIcon />

            <Text variant="text">{`${dayjs(room?.startTime).format(
              'HH:mm'
            )} às ${dayjs(room?.endTime).format('HH:mm')}`}</Text>
          </Stack>
        );
      },
      header: () => <span>Horário</span>,
    }),

    columnHelper.accessor('bookingRooms', {
      cell: (info) => {
        const rooms = info.getValue();
        return (
          <Stack direction="row" alignItems="center">
            <FiBox />

            <Text variant="text">
              {rooms.length === 1
                ? `${rooms[0].room?.description}`
                : rooms.length === 0
                ? '-'
                : 'Duas salas'}
            </Text>
          </Stack>
        );
      },
      header: () => <span>Sala</span>,
    }),

    columnHelper.accessor('tickets', {
      cell: (info) => (
        <Stack direction="row" alignItems="center">
          <FaTicketAlt />
          <Text variant="text">{`${info.getValue()} ingressos`}</Text>
        </Stack>
      ),
      header: () => <span>Ingressos</span>,
    }),

    columnHelper.accessor('bookingTypeID', {
      cell: (info) => {
        let label = 'Evento';
        if (info.getValue() === 5) label = 'Lugar na sala';
        if (info.getValue() === 1) label = 'Sala inteira';

        return (
          <Stack direction="row" alignItems="center">
            <Text variant="text">{label}</Text>
          </Stack>
        );
      },
      header: () => <span>Tipo de Reserva</span>,
    }),

    columnHelper.accessor('bookingID', {
      cell: (info) => (
        <Stack direction="row" alignItems="center">
          <Tooltip label={info.getValue()}>
            <Text variant="text">{`${truncateName(info.getValue(), 15)}`}</Text>
          </Tooltip>
        </Stack>
      ),
      header: () => <span>Id</span>,
    }),

    columnHelper.accessor('bookingStatus', {
      cell: (info) => (
        <Stack direction="row" alignItems="center">
          <Text variant="text">{`${info.getValue()?.description}`}</Text>
        </Stack>
      ),
      header: () => <span>status da reserva</span>,
      sortingFn: (rowA, rowB, columnId) => {
        const statusA: any = rowA.getValue(columnId);
        const statusB: any = rowB.getValue(columnId);

        return statusA.bookingStatusID > statusB.bookingStatusID
          ? 1
          : statusA.bookingStatusID < statusB.bookingStatusID
          ? -1
          : 0;
      },
    }),

    columnHelper.accessor('created', {
      cell: (info) => (
        <Stack direction="row" alignItems="center">
          <Text variant="text">
            {dayjs
              .utc(info.getValue())
              .tz('America/Sao_Paulo')
              .format('DD/MM/YYYY HH:mm')}
          </Text>
        </Stack>
      ),
      header: () => <span>data da venda</span>,
    }),

    columnHelper.accessor('paymentMethodID', {
      cell: (info: any) => (
        <Stack direction="row" alignItems="center">
          <Text variant="text">{typePayment(info.getValue())}</Text>
        </Stack>
      ),
      header: () => <span>tipo de pagamento</span>,
    }),

    columnHelper.display({
      id: 'actions',
      cell: (info) => {
        const {
          isOpen: isCancelOpen,
          onOpen: onCancelOpen,
          onClose: onCancelClose,
        } = useDisclosure();
        const toast = usePHToast();
        const bookingID = info.row.original.bookingID;

        const {
          onClose: onRescheduleClose,
          onOpen: onRescheduleOpen,
          isOpen: isRescheduleOpen,
        } = useDisclosure();

        const handleCancel = async () => {
          setLoading(true);
          try {
            await cancelBooking(bookingID);
            toast({
              status: 'success',
              description: 'Reserva cancelada com sucesso!',
            });
            realoadGet();
          } catch {
            toast({
              status: 'error',
              description: 'Erro ao cancelar a reserva!',
            });
          } finally {
            setLoading(false);
            onCancelClose();
          }
        };

        const handleRescheduleBooking = () => {
          if (![1, 5].includes(info.row.original.bookingTypeID)) {
            return navigate(`/eventos/reagendar/${bookingID}`);
          }
          return navigate(`/jogador/reagendar/${bookingID}`);
        };

        return (
          <HStack spacing={4} pl={6}>
            <Spacer borderRight="2px dashed #B0CA0A" />
            <Box
              justifyContent="center"
              as="button"
              _hover={{ color: '#B0CA0A' }}
              onClick={() => {
                navigate(`/admin/ingressos/editar/${bookingID}`);
              }}
            >
              <Icon as={FaEdit} w="20px" h="20px" />
              <Text>Editar</Text>
            </Box>
            <Box
              justifyContent="center"
              as="button"
              _hover={{ color: '#B0CA0A' }}
              onClick={() => {
                onRescheduleOpen();
              }}
            >
              <Icon as={TimeIcon} w="20px" h="20px" />
              <Text>Reagendar</Text>
            </Box>
            <Box
              justifyContent="center"
              as="button"
              _hover={{ color: '#B0CA0A' }}
              onClick={onCancelOpen}
            >
              <Icon as={FaRegWindowClose} w="20px" h="20px" />
              <Text>Cancelar</Text>
            </Box>
            <ErrorModal
              isOpen={isCancelOpen}
              onClose={onCancelClose}
              loading={loading}
              content={'DESEJA CANCELAR A RESERVA?'}
              handleMainClick={handleCancel}
            />
            <PophausModal
              icon={BsAlarm}
              isOpen={isRescheduleOpen}
              onClose={onRescheduleClose}
              content="Deseja reagendar?"
              handleMainClick={handleRescheduleBooking}
              loading={loading}
              secondaryButtonText="voltar"
              handleSecondaryClick={onRescheduleClose}
            />
          </HStack>
        );
      },
    }),
  ];

  return columns;
};
