import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Flex, HStack, Text } from '@chakra-ui/react';
import useCountdown from 'src/hooks/useCountdown';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import usePHToast from 'src/hooks/useToast';
import {
  BookingStatusEnum,
  cancelBooking,
  updateBookingStatus,
  useGetBookingByID,
} from 'src/api/services/booking';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { BookingActions } from 'src/redux/booking';
import dayjs from 'dayjs';
import { RootState } from 'src/redux/store';
import { getBookingTransactionsByBookingID } from 'src/api/services/charge';
import { BookingResult } from 'src/api/interfaces/booking';
import { ApiRepository } from 'src/api';

export interface PophausTimerProps {
  payment?: boolean;
  playersIdentification?: boolean;
}

// export const PophausTimer = ({ payment = false }: PophausTimerProps) => {
//   const { bookingID } = useParams<{ bookingID: string }>();
//   const navigate = useNavigate();
//   const toast = usePHToast();
//   const dispatch = useAppDispatch();
//   const { booking } = useAppSelector((state) => state.booking);
//   // const {
//   //   text: timerText,
//   //   seconds,
//   //   isCompleted,
//   // } = useCountdown(booking.timer ?? 300);
//   const {
//     text: timerText,
//     seconds,
//     isCompleted,
//   } = useCountdown(payment ? 300 : 120)

//   const handleCancel = useCallback(async () => {
//     try {
//       if (bookingID) {
//         await updateBookingStatus(bookingID, BookingStatusEnum.EXPIRADA);
//         toast({
//           status: 'error',
//           description: 'Reserva expirada.',
//         });
//         dispatch(BookingActions.resetBooking())
//       }
//       navigate(`/`);

//     } catch (e: any) {
//       toast({
//         status: 'error',
//         description: 'Reserva cancelada. Por favor, tente fazer outra reserva.',
//       });
//       navigate(`/`);
//     }
//   }, []);

//   // useEffect(() => {
//   //   if (payment) {
//   //     dispatch(BookingActions.setPaymentTimer(seconds))
//   //   } else {
//   //     dispatch(BookingActions.setTimer(seconds));
//   //   }
//   // }, [seconds]);

//   useEffect(() => {
//     if (isCompleted) handleCancel();
//   }, [isCompleted]);

//   return (
//     <Flex
//       direction="row"
//       w="full"
//       bg="blue"
//       align="center"
//       justify="center"
//       py={2}
//       gap={1}
//     >
//       {payment ? <Text fontSize={'xs'} color="black" fontWeight={700}>
//         A partir desta etapa, o seu horário está pré-reservado. TEMPO PARA FINALIZAR A COMPRA:
//       </Text> : <Text fontSize={'xs'} color="black" fontWeight={700}>
//         Atenção, até esta etapa o seu horário ainda não está garantido. TEMPO PARA CONTINUAR:
//       </Text>}

//       <Text fontSize={'md'} color="black" fontWeight={700}>
//         {timerText}
//       </Text>
//     </Flex>
//   );
// };

const SECOND = 1000;
const MINUTE = SECOND * 60;

export const PophausTimer = ({
  payment = false,
  playersIdentification = false,
}: PophausTimerProps) => {
  const { bookingID } = useParams<{ bookingID: string }>();
  const navigate = useNavigate();
  const toast = usePHToast();
  const dispatch = useAppDispatch();
  const { bookingId, bookingStatusID } = useAppSelector(
    (state: RootState) => state.booking.booking
  );

  const [deadline, setDeadline] = useState<string>('');
  const [parsedDeadline, setParsedDeadline] = useState<number>(1);
  const [time, setTime] = useState<number>(1);
  const {
    data: booking,
    mutate,
    isLoading,
    isValidating,
  } = useGetBookingByID(bookingID ?? bookingId);
  const api = new ApiRepository();
  const apiFromLocalGateway =
    api.apiInstance.getUri() === 'https://localhost:7074/api/';

  const handleCancel = useCallback(async () => {
    try {
      if (bookingID) {
        await updateBookingStatus(bookingID, BookingStatusEnum.EXPIRADA);
      }
      toast({
        status: 'error',
        description: 'Reserva expirada.',
      });
      dispatch(BookingActions.resetBooking());
      navigate(`/`);
    } catch (e: any) {
      toast({
        status: 'error',
        description: 'Reserva cancelada. Por favor, tente fazer outra reserva.',
      });
      navigate(`/`);
    }
  }, []);

  const setDeadlineFromPixTransaction = () => {
    getBookingTransactionsByBookingID(bookingID)
      .then((res) => {
        if (res[0] && res[0].pixGenerationTime) {
          setDeadline(res[0].pixGenerationTime);
        }
      })
      .catch(() => {
        if (booking) setDeadline(booking.bookingStatusUpdated);
      });
  };

  useEffect(() => {
    if (booking) {
      if (booking.paymentMethodID === 3) {
        setDeadlineFromPixTransaction();
      } else {
        setDeadline(booking.bookingStatusUpdated);
      }
    }
  }, [booking?.bookingStatusID]);

  useEffect(() => {
    if (payment) {
      const d = dayjs(deadline)
        .add(booking?.paymentMethodID === 3 ? 8 : 7, 'minutes')
        .subtract(apiFromLocalGateway ? 0 : 3, 'hours')
        .toString();
      setParsedDeadline(Date.parse(d));
    } else if (playersIdentification && booking?.bookingTypeID === 5) {
      const d = dayjs(deadline)
        .add((booking?.tickets || 0) * 2, 'minutes')
        .subtract(apiFromLocalGateway ? 0 : 3, 'hours')
        .toString();
      setParsedDeadline(Date.parse(d));
    } else {
      const d = dayjs(deadline)
        .add(5, 'minutes')
        .subtract(apiFromLocalGateway ? 0 : 3, 'hours')
        .toString();
      setParsedDeadline(Date.parse(d));
    }
  }, [deadline]);

  useEffect(() => {
    setTime(parsedDeadline - Date.now());
  }, [parsedDeadline]);

  useEffect(() => {
    const interval = setInterval(
      () => setTime(parsedDeadline - Date.now()),
      1000
    );

    return () => clearInterval(interval);
  }, [parsedDeadline]);

  useEffect((): any => {
    if (
      !isValidating &&
      !isLoading &&
      parsedDeadline !== 1 &&
      dayjs(Date.now()).isAfter(parsedDeadline)
    )
      handleCancel();
  }, [time]);

  useEffect(() => {
    mutate();
  }, [bookingStatusID]);

  return (
    <Flex
      direction="row"
      w="full"
      bg="blue"
      align="center"
      justify="center"
      py={2}
      gap={1}
    >
      <Text fontSize={'xs'} color="black" fontWeight={700}>
        A partir desta etapa, o seu horário está pré-reservado. TEMPO PARA
        FINALIZAR A COMPRA:
      </Text>
      <Text fontSize={'md'} color="black" fontWeight={700}>
        {time > 0
          ? `${Math.floor((time / MINUTE) % 60)}:${
              Math.floor((time / SECOND) % 60) < 10
                ? `0${Math.floor((time / SECOND) % 60)}`
                : `${Math.floor((time / SECOND) % 60)}`
            }`.padStart(2, '0')
          : '0:00'}
      </Text>
    </Flex>
  );
};
